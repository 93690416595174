<template>
    <section id="imprint">
        <base-card>
          <v-container class="my-5">
            <v-skeleton-loader
                type="paragraph@3"
                :loading="loading"
                class="mt-5"
            >
              <template v-slot:default>
                <v-card-text
                    class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto my-5"
                    v-html="imprint"
                >
                </v-card-text>
              </template>
            </v-skeleton-loader>
          </v-container>
          <v-card-title
              id="imprint"
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="justify-center font-weight-bold grey--text text--darken-4 mb-0 mt-md-6 text-uppercase logo"
          >
            {{$t('imprint.privacy')}}
          </v-card-title>
          <v-container class="my-5">
            <v-skeleton-loader
                type="paragraph@3"
                :loading="loading"
                class="mt-5"
            >
              <template v-slot:default>
                <v-card-text
                    class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto my-5"
                    v-html="privacy"
                >
                </v-card-text>
              </template>
            </v-skeleton-loader>
          </v-container>
        </base-card>
    </section>
</template>

<script>

import {fetchData, query_IMPRINT, query_PRIVACY} from "@/utils/api"
import i18n from "@/plugins/i18n"
import showdown from "showdown"

export default {
  data: () => ({
    imprint: {},
    privacy: {},
    loading: true
  }),
  methods: {
    getImprint: async () => {
      const query = query_IMPRINT(i18n.locale)
      return await fetchData(query)
    },
    getPrivacy: async () => {
      const query = query_PRIVACY(i18n.locale)
      return await fetchData(query)
    }
  },
  async created() {
    const imprint = await this.getImprint()
    const privacy = await this.getPrivacy()
    const converter = new showdown.Converter()
    this.imprint = converter.makeHtml(imprint.textContent.text)
    this.privacy = converter.makeHtml(privacy.textContent.text)
    this.loading = false
  }
}
</script>
